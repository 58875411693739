
import { defineComponent, ref } from "vue";
import axios from "axios";
import HomeSlider from "@/components/HomeSlider.vue";
import { i18n } from "@/i18n";

export default defineComponent({
  name: "Home",
  components: {
    "app-home-slider": HomeSlider
  },
  setup() {
    const { t } = i18n.global;
    const helpEmail = ref();
    const helpMessage = ref();
    const isSending = ref(false);
    const isSent = ref(false);
    const sentWithError = ref(false);

    const submitHelpEmail = () => {
      isSending.value = true;
      console.log("submitHelpEmail():", helpEmail.value, helpMessage.value);
      axios
        .post("https://api.metacriacoes.com/friesland-campina/app-friso/v1/", {
          action: "requestContact",
          email: helpEmail.value,
          message: helpMessage.value
        })
        .then(resp => resp.data)
        .then(data => {
          isSending.value = true;
          if (data.success) {
            isSent.value = true;
            setTimeout(() => {
              isSent.value = false;
              helpEmail.value = "";
              helpMessage.value = "";
            }, 3000);
          } else {
            sentWithError.value = true;
            helpEmail.value = "";
            helpMessage.value = "";
          }
        })
        .catch(err => {
          console.error(err);
          sentWithError.value = true;
          helpEmail.value = "";
          helpMessage.value = "";
        })
        .finally(() => {
          isSending.value = false;
        });
    };

    return {
      t,
      helpEmail,
      helpMessage,
      submitHelpEmail,
      isSending,
      isSent,
      sentWithError
    };
  }
});
