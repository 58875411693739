import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f5e892a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slide-overlay h-full flex flex-col justify-center pb-36" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "appear" }, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "slide h-full",
            onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('hover', true))),
            onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('hover', false)))
          }, [
            _createElementVNode("div", _hoisted_1, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ])
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}